/* SignInPage.css */
.signin-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;
  background-color: #000000;
}

@media only screen and (max-width: 600px) { .signin-page-container {
  padding: 100px; /* Adjust the padding to your preference */
}}

h1 {
  margin-bottom: 0px;
}

h3 {margin-bottom: 30px;}