 .task-list-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px;
    padding: 20px;
    background: linear-gradient(to bottom, #000000, #6a1f9531);
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
    
  .task-item {
    background-color: #000000;
    font-size: 13px;
    color: #ffffff;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr auto; /* Adjust the width of the columns */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    gap: 10px; /* Add gap between columns */
  }
  
  .task-item > div:first-child {
    word-wrap: break-word; /* Allow long task descriptions to wrap */
  }

  .title{font-size:30px;}
    
  body {
    background-color: #000000;
    color: #ffffff;
  }
    
  .subtext {
    font-size: 14px;
    color: #ffffff;
    margin-top: -25px;
    margin-bottom: 10px;
  }
    
  .add-task-container {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 15px;
    padding:0px 10px;
  }

  h1, h2, h4, p {
    text-align: center; /* Align text center */
    
  }

  h1 {font-size:100px;}
  
    
  .task-input {
    
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.519);
    border-radius: 20px;
    background-color:#ffffff;
    color: #000000a1;
    font-size: 14px; /* Increase font size */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
  
  }
  
  .task-input::placeholder {
    color: rgba(93, 44, 44, 0.7); /* Change placeholder text color */
  }
    
  
    .add-task-button {
      padding: 7px 20px;
      font-size: 12px;
      border-radius: 12px;
      /* Gradient background */
      background: linear-gradient(45deg, #8900d8a9, /* purple */ #00c3ff43); /* Replace #FF5733 with your desired color */
      border: 1px solid rgba(255, 255, 255, 0.519);
      color: #ffffff;
      cursor: pointer;
      transition: background-color 0.3s ease;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
    }
  
    .points{margin-top:0px;}
    
  
    .done {
      background-color: #8900fa16; /* Change this to the desired background color */
    }
  
  
    .task-item.task-overdue {
      background-color: rgba(209, 0, 0, 0.353);
    }
    
  .prompt-boss-button { /* Apply same style to the prompt-boss-button */
    padding: 7px 40px;
    font-size: 14px;
    border-radius: 12px;
    /* Gradient background */
    background: linear-gradient(45deg, #8900d8a9, /* purple */ #00c3ff43); /* Replace #FF5733 with your desired color */
    border: 1px solid rgba(255, 255, 255, 0.519);
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;}
    
  .add-task-button:hover { /* Apply same hover style */
    background: linear-gradient(45deg, #1fecffa9, /* purple */ #6100d0cc);
  }
  
  
  .add-ai-task-button{ padding: 7px 10px;
    font-size: 1px;
    border-radius: 12px;
    /* Gradient background */
    background: linear-gradient(45deg, #8900d8a9, /* purple */ #00c3ff43); /* Replace #FF5733 with your desired color */
    border: 1px solid rgba(255, 255, 255, 0.519);
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;}
  
  .prompt-boss-button:hover { /* Apply same hover style */
    background: linear-gradient(45deg, #1fecffa9, /* purple */ #6100d0cc);
  }
    
  .delete-task-button {
    background-color: #ff4d4d;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
    
  .delete-task-button:hover {
    background-color: #e63946;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .dropdown-content button {
    display: block;
    width: 100%;
    text-align: left;
    padding: 10px;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .chatbox {
      border: 1px solid #aaa;
    padding: 30px;
    margin: 10px;
    height: 200px;
    overflow-y: auto;
    border-radius:20px;
  }
  
  .chatbox > div {
    margin-bottom: 30px; /* Add space between each message */
  }
  
  .chatbox-input {
    margin-top: 10px; /* Add space between the chatbox and input box */
  }
  
  
  
  /* TaskListPage.css */
  .sorting-controls {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .sorting-controls button {
    padding: 8px 20px;
    font-size: 12px;
    border-radius: 13px;
    background-color: #0c0013;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    border-color:rgba(255, 255, 255, 0.519);
    border-width:1px,
  }
  
  
  
  .sorting-controls button:hover {
    background-color: #230046;
  }
  
  /* Media query for screens with a maximum width of 600 pixels (typical for mobile devices) */
  @media only screen and (max-width: 600px) {
    .sorting-controls {
    
      align-items: center;
    }
  
    .sorting-controls button {
      font-size: 12px; /* Adjusted font size for mobile */
      padding: 8px 15px; /* Adjusted padding for mobile */
    }
  }
  
  .options-button {
    padding: 7px 10px;
    font-size: 11px;
    border-radius: 12px;
    /* Gradient background */
    background: linear-gradient(45deg, #59008da9, /* purple */ #00c3ff43); /* Replace #FF5733 with your desired color */
    border: 1px solid rgba(255, 255, 255, 0.519);
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
  }
  
  /* Hover effect */
  .options-button:hover {
    background-color: #671eb14f;
  }
  
  .dropdown-content {
    display: none; /* Hide the dropdown content by default */
    position: absolute; /* Position the dropdown content */
    background-color: transparent; /* Make the background color transparent */
    min-width: 100px; /* Minimum width of the dropdown content */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Box shadow for a nice effect */
    z-index: 1; /* Ensure the dropdown content appears above other content */
  }
  
  
  /* Style the delete button differently */
  .delete-task-button {
    background-color: #f44336; /* Red background */
    border-radius: 12px;
    color:white;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    font-size:11px;
  }
  
  .edit-task-button {
    background-color: #21001b; /* Red background */
    border-radius: 12px;
    color:white;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    font-size:11px;
  };
  
  
  /* Hover effect */
  .dropdown-content button:hover {
    background-color: #080808; /* Darker green */
  };
  
  /* Hover effect for delete button */
  .delete-task-button:hover {
    background-color: #d32f2f; /* Darker red */
  };
  
  
  .edit-task-button:hover {
    background-color: #d32f2f; }
  
  
  
  
  
  