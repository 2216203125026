.signin-form {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 60px;
    padding-top: 60px;
    border-radius: 20px;
    background: linear-gradient(45deg, #8900d8a9, /* purple */ #00c3ff43);
    border: 1px solid rgba(255, 255, 255, 0.519);
}



  
  h1 {
    margin-bottom: 5px; /* Reduce bottom margin for the h1 element */
    font-size:22px;
}

h3 {
    margin-top: 5px; 
    font-size:15px;/* Reduce top margin for the h3 element */
  
}



  .signin-input {
    background-color: #44444440; /* Light grey background */
    border: 1px solid #ccc; /* Add border for better visibility */
    padding: 7px; /* Add padding for better appearance */
    padding-left:50px;
    padding-right:50px;
    margin-bottom: 10px; /* Add margin to separate inputs */
    border-radius: 70px; /* Round the corners of the input boxes */
    font-size:14px;
    font-family: 'Poppins';
    color:white;
    font-style: normal;
    font-weight: 400;
  }
  .signin-input::placeholder {
    color: rgba(195, 195, 195, 0.7); /* Change placeholder text color */
}
  
  
  .signin-form {
    text-align: center; /* Center align the content */
}

.signin-button {
    display: inline-block;
    padding: 8px 70px;
    background: linear-gradient(45deg, #8900d8a9, /* purple */ #00c3ff43); /* Replace #FF5733 with your desired color */
    border: 1px solid rgba(255, 255, 255, 0.519);
    margin-top: 10px;
    margin-left: auto; /* Set left margin to auto */
    margin-right: auto; /* Set right margin to auto */
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
    font-size: 13px;
    border-radius: 12px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
}

.signin-error {
    font-size: 12px;
  }
  
  
  .signin-link {
    /* Add your link styles here */
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2) format('woff2');
    font-display: swap;
  }
  
  /* Set Poppins as the main font */
  body {
    font-family: 'Poppins', sans-serif;
  }
  