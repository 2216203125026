/* Header.css */
.header {
  background-color: #000000;
  padding: 10px 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 80px; /* Adjust the width according to your preference */
  height: auto; /* Maintain aspect ratio */
  border-radius: 50%; 
}

/* Media query for screens with a maximum width of 600 pixels (typical for mobile devices) */
@media only screen and (max-width: 600px) {
  .logo {
    width: 50px; /* Adjust the width for smaller screens */
  }
}


nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav ul li {
  margin-right: 20px; /* Adjust as needed */
}

nav ul li:last-child {
  margin-right: 0;
}

nav ul li a {
  color: white; /* Set text color to white */
  text-decoration: none; /* Remove underline */
  font-size:13px;
}

nav ul li a:hover {
  text-decoration: underline; /* Add underline on hover */
}
