/* HomePage.css */

.landing-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Set the height of the landing page to be equal to the viewport height */
  }
  
  .content-container {
    text-align: center;
    margin: auto;
    margin-top:30px;
    padding: 0 20px; /* Add padding to the sides to give some space on smaller screens */
    max-width: 100%; /* Set maximum width to 100% to ensure it fits within the viewport */
  }
  
  .btn {
    display: inline-block;
    padding: 8px 50px;
    background: linear-gradient(45deg, #8900d8a9, /* purple */ #00c3ff43); /* Replace #FF5733 with your desired color */
    border: 1px solid rgba(255, 255, 255, 0.519);
    margin-top: 20px;
    
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
    font-size: 14px;
  border-radius: 12px;
  margin-bottom:50px;
  }


  
  .btn:hover {
    background: linear-gradient(45deg, #1fecffa9, /* purple */ #6100d0cc);
  }

  .logo2 {
    width: 170px; /* Adjust the width according to your preference */
    height: auto; /* Maintain aspect ratio */
    border-radius: 50%; 
  }
  